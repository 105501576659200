import React from 'react';
import classNames from 'classnames';
import { I18nextContext, Link } from 'gatsby-plugin-react-i18next';

function CategoryProjectList({
  title: categoryTitle,
  description: categoryDescription,
  projects,
}) {
  const { language } = React.useContext(I18nextContext);
  return (
    <div className="w-full overflow-hidden rounded-lg sm:ring-1 sm:ring-slate-900/10">
      <div className="sm:bg-gray-50">
        <div className="max-w-7xl mx-auto p-4 lg:p-6">
          <div className="sm:flex sm:items-baseline sm:justify-between">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
              {categoryTitle}
            </h2>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 xl:gap-6">
            {projects?.map(
              ({ title, description, isBig, images, slug }, index) => {
                const projectTitle = title[language];
                const projectDescription = description[language];
                const image = images[0];
                const imageWrapperClasses = classNames(
                  'group aspect-w-2 aspect-h-1 rounded-lg overflow-hidden',
                  {
                    'sm:aspect-h-1 sm:aspect-w-1 sm:row-span-2 sm:h-full':
                      isBig,
                  },
                  {
                    'sm:relative sm:aspect-none sm:h-52': !isBig,
                  }
                );
                const imageClasses = classNames(
                  'object-center object-cover group-hover:opacity-75',
                  {
                    'sm:h-full': isBig,
                    'object-center object-cover group-hover:opacity-75 sm:absolute sm:inset-0 sm:w-full sm:h-full':
                      !isBig,
                  }
                );
                const smallClasses = {
                  'sm:absolute sm:inset-0': !isBig,
                };
                return (
                  <div className={imageWrapperClasses} key={index}>
                    <img
                      src={image.asset.url}
                      alt={image.caption}
                      className={imageClasses}
                    />
                    <div
                      aria-hidden="true"
                      className={classNames(
                        'bg-gradient-to-b from-transparent to-black opacity-50',
                        smallClasses
                      )}
                    />
                    <div
                      className={classNames(
                        'p-4 sm:p-6 flex items-end',
                        smallClasses
                      )}
                    >
                      <div>
                        <h3 className="font-semibold text-sm md:text-base text-white">
                          <Link to={`/project/${slug.current}`}>
                            <span className="absolute inset-0" />
                            {projectTitle}
                          </Link>
                        </h3>
                        <p
                          aria-hidden="true"
                          className="mt-1 text-xs sm:text-sm text-white"
                        >
                          {projectDescription}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CategoryProjectList;
