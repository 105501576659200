import React from 'react';
import { graphql } from 'gatsby';
import { I18nextContext, Trans } from 'gatsby-plugin-react-i18next';
import Container from '../components/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import Layout from '../containers/layout';

import CategoryList from '../components/category-list';

export const query = graphql`
  query allCategories($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    categories: allSanityCategory(sort: { fields: title___bg, order: DESC }) {
      nodes {
        id: _id
        description: _rawDescription
        title: _rawTitle
      }
    }
    projects: allSanityProject(sort: { fields: title___bg, order: DESC }) {
      nodes {
        title: _rawTitle
        description: _rawDescription
        slug {
          current
        }
        category {
          id: _id
        }
        images {
          asset {
            url
          }
          caption
          key: _key
        }
        isBig
      }
    }
    page: sanityPage(title: { en: { eq: "Projects" } }) {
      title {
        bg
        en
      }
    }
  }
`;

const ProjectsHeader = () => (
  <div className="relative mt-24 max-w-md mx-auto px-4 sm:max-w-3xl sm:mt-32 sm:px-6 lg:max-w-7xl lg:px-8">
    <h1 className="text-4xl font-extrabold tracking-tight text-white sm:text-5xl lg:text-6xl">
      <Trans>Projects</Trans>
    </h1>
  </div>
);

const ProjectsPage = (props) => {
  const { data, errors } = props;
  const { language } = React.useContext(I18nextContext);
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  const categoryNodes = data?.categories?.nodes ?? [];

  return (
    <Layout headerChildren={<ProjectsHeader />}>
      <SEO title={data?.page?.title[language]} />
      <Container>
        <div className="grid gap-y-6 sm:gap-x-4 xl:gap-6">
          {categoryNodes.map((category) => {
            const projects = data?.projects?.nodes;
            const categoryProjects = projects.filter(
              (project) => project?.category?.id === category.id
            );
            return (
              !!categoryProjects?.length && (
                <CategoryList
                  title={category?.title[language]}
                  description={category?.description[language]}
                  projects={categoryProjects}
                  key={category?.id}
                />
              )
            );
          })}
        </div>
      </Container>
    </Layout>
  );
};

export default ProjectsPage;
